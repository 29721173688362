/* --header css start-- */
/* -top header css start- */
.topheaderdiv {
  background: #212529;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 13px 48px;
}
.topheadertext {
  margin: 0;
  color: #9d9d9e;
  font-family: "Roboto", sans-serif;
  font-size: 87%;
  display: flex;
  align-items: center;
  border-right: 1px solid #9d9d9e;
  padding-right: 35px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.topheadertext a {
  text-decoration: none;
  color: unset;
}
.topheadertext:hover {
  color: #e4e4e4;
}
.topheadertext:last-child {
  border-right: none;
  padding-right: 0;
}
svg.MuiSvgIcon-root.topheadericon {
  width: 19px;
  height: 19px;
  margin-right: 11px;
}
.topheaderinnerdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 87%;
}
.header-home-icon {
  margin-right: 11px;
}
.topheaderinnerdiv .MuiIcon-root {
  width: 22px;
  height: 19px;
  cursor: pointer;
}

/* -top header css end- */
.logodiv {
  position: absolute;
  width: 105px;
  height: 105px;
  top: 0;
  left: 4%;
  z-index: 9;
  cursor: pointer;
}
img.logoimg {
  width: 100%;
  height: 100%;
}
.headersectionstart {
  position: relative;
}
.menu-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  color: aliceblue;
  visibility: hidden;
  cursor: pointer;
}
.menu-icon span.material-icons.MuiIcon-root {
  font-size: 30px;
}
.leftsidemenu {
  color: #fff;
  display: block;
  background-color: #212529;
  height: 100%;
}
.leftsidemenu p {
  color: #fff;
}
.MuiDrawer-paperAnchorLeft {
  width: 55%;
}
.bottomheader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  height: 59px;
  visibility: visible;
}
.multiLevelDropdownDiv {
  margin-top: 6px;
}
.multiLevelDropdownDiv button {
  background-color: transparent;
  font-size: 16px;
  margin: 0 32px;
  cursor: pointer;
  color: #282a2e;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
  transition: 0.3s;
  padding: 19px 7px;
  border-bottom: 2px solid transparent;
  padding: 19px 7px;
}
.multiLevelDropdownDiv .Dropdown_menuRight__3W1m9 {
  z-index: 999999;
}
.multiLevelDropdownDiv button:hover {
  border-color: transparent;
}
.multiLevelDropdownDiv button.Dropdown_disabled__tlsq5 {
  opacity: 0.2;
}
.main-menu.disableText {
  pointer-events: none;
  opacity: 0.2;
}
p.bootomheadertext {
  margin: 0 32px;
  cursor: pointer;
  color: #282a2e;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
  transition: 0.3s;
  padding: 19px 7px;
  border-bottom: 2px solid transparent;
  padding: 19px 7px;
  position: relative;
}
span.bottomheadersubtxt {
  position: absolute;
  top: 5px;
  font-size: 76%;
  color: #cf453f;
}
p.bootomheaderactivetext {
  margin: 0 32px;
  cursor: pointer;
  color: #de412e;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
  transition: 0.3s;
  padding: 18px 7px;
  position: relative;
  /* border-bottom: 1px solid #de412e; */
  /* border-bottom: 2px solid #de412e; */
}
p.bootomheaderactivetext::before {
  position: absolute;
  content: "";
  content: "";
  position: absolute;
  background: #c73a2b;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
}
p.bootomheadertext.headertxtwithsubtxt {
  position: relative;
}
p.bootomheadertext:hover {
  color: #de412e;
  /* border-bottom: 2px solid #de412e; */
  /* border-bottom: 1px solid #de412e; */
  /* background: #fdf6f5; */
}
p.bootomheadertext::before {
  position: absolute;
  content: "";
  content: "";
  position: absolute;
  background: #c73a2b;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  width: 0;
  overflow: hidden;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
p.bootomheadertext:hover::before {
  width: 100%;
}
p.bootomheadertext:focus {
  /* color: #de412e; */
  border-bottom: 2px solid #de412e;
  /* background: #fdf6f5; */
}
.headerpoprover .MuiTabs-flexContainer.MuiTabs-flexContainerVertical {
  height: 100%;
}
.headerpoprover .MuiTab-root {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 40px;
  font-size: 14px;
  text-transform: capitalize;
  font-family: "Roboto";
  color: #282a2e;
  opacity: 1;
}
.headerpoprover .MuiTabs-root.makeStyles-tabs-2.MuiTabs-vertical {
  height: 100%;
  min-height: 358px;
}
.headerpoprover span.MuiTab-wrapper {
  align-items: flex-start;
  justify-content: flex-start;
}
.headerpoprover .MuiBox-root {
  width: 490px;
  padding: 0 20px;
  height: 100%;
}
.headerpoprover p.MuiTypography-root {
  height: 100%;
}
.navmenuimagediv {
  width: 98%;
  height: auto;
  /* margin: 0 auto; */
}
.navmenuimagediv.fornavbartablesec {
  width: 66%;
  /* height: auto;
    margin-top: 17px; */
}
.navmenuimagediv.navimgextradiv {
  height: 290px;
}
.navbarrighttabsec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.navmenuimagediv img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.headerpoprover .MuiTabs-indicator {
  display: none;
}
.headerpoprover .MuiTab-textColorInherit.Mui-selected {
  color: #de412e;
}
.headerpoprover
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  min-height: 359px;
  height: auto;
  /* width: 100%; */
  min-width: 700px;
  max-width: 900px;
  padding: 14px;
  top: 105px !important;
  left: 246px !important;
}
.tabbottomdiv h2 {
  margin: 0 auto;
  width: 40%;
  text-align: center;
}
.headerpoprover
  span.PrivateTabIndicator-root-171.PrivateTabIndicator-colorSecondary-173.MuiTabs-indicator.PrivateTabIndicator-vertical-174 {
  display: none;
}
.main-menu:hover .headerpoprover {
  display: block;
}
.headerpoprover {
  display: none;
}
.headerpoprover {
  position: absolute;
  top: 100%;
  z-index: 999999;
  height: auto;
  padding: 14px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 4px;
  width: 205px;
}
.main-menu:hover > .bootomheadertext::before {
  width: 100%;
  /* border-bottom: 2px solid #de412e; */
}
.main-menu:hover > .bootomheadertext {
  color: #de412e;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.buttonclass.viewdetailbtn {
  background: #c73a2b;
  color: #fff;
  text-transform: capitalize;
  font-size: 85%;
  font-family: "Roboto";
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.buttonclass.viewdetailbtn:hover {
  background: #de412e;
}
/* --header css end-- */

/* --------FooterSection css start----------- */
.footersectionstart {
  padding: 2em 0 0em;
  background: url("../../../Images/footerbg.png");
  position: relative;
  background-position: top;
  background-size: cover;
}
.footcopyrightsec p a {
  color: #1b1b1b;
  text-decoration: none;
}
.footcopyrightsec p a:hover {
  text-decoration: underline;
}
.footcolhead p {
  margin: 0 0 30px;
  font-size: 96%;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 400;
  position: relative;
}
.footcolhead p:after {
  content: "";
  position: absolute;
  background: #c73a2b;
  width: 23%;
  height: 2px;
  bottom: -9px;
  left: 0px;
}
.footcol2 .footcolhead p:after {
  /* width: 9%; */
}
.footcol4 .footcolhead p:after {
  /* width: 35%; */
}
.footlogo {
  width: 70px;
  height: auto;
}
.footbottompara p {
  margin: 19px 0 0;
  font-size: 82%;
  font-family: "Roboto";
  line-height: 20px;
  color: #777373;
}
.foot-abouttext p {
  font-size: 15px;
  line-height: 22px;
}
.footerquickmenu ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
.footerquickmenu li {
  font-size: 90%;
  margin-bottom: 12px;
  color: #3c3b3b;
  font-weight: 400;
  font-family: "Roboto";
  cursor: pointer;
}
.footerquickmenu li a {
  color: unset;
  text-decoration: none;
}
p.showRoomdetail {
  margin: 0;
  font-size: 82%;
  font-family: "Roboto";
  line-height: 20px;
  color: #777373;
  font-weight: 400;
}
p.showRoomdetail:first-child {
  font-weight: 500;
  color: #464646;
}
.show-googmap {
  text-decoration: none;
  color: #3c3b3b;
  font-weight: 400;
  font-family: "Roboto";
  cursor: pointer;
}
.show-googmap:hover {
  color: #de412e;
}
p.businessHours {
  margin: 0;
  font-size: 82%;
  font-family: "Roboto";
  line-height: 20px;
  color: #777373;
  font-weight: 400;
}
.footerquickmenu li:hover {
  color: #de412e;
}
.footerdeskmenu li:hover {
  color: #de412e;
}
.footerquickmenu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerdeskmenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerdeskmenu ul {
  list-style-type: none;
  padding: 0;
  width: 70%;
  margin-top: 0;
}
.footerdeskmenu li {
  font-size: 90%;
  margin-bottom: 22px;
  color: #3c3b3b;
  font-weight: 400;
  font-family: "Roboto";
  cursor: pointer;
}
p.contacttitle {
  font-size: 85%;
  color: #737373;
  margin: 0 0 4px;
}
p.contactemail {
  font-size: 78%;
  color: #020202;
  margin: 0 0 11px;
  font-weight: 400;
}
p.contactemail a {
  color: unset;
  text-decoration: none;
}
.footcopyrightsec {
  padding-bottom: 7em;
  border-top: 1px solid #cccccc;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1em 3em;
}
.footcopyrightsec p {
  margin: 0;
  font-size: 78%;
  color: #696969;
  padding: 5px;
}
.footerTopSec {
  padding: 1em 4em;
}
.socialicondiv img {
  margin-right: 13px;
  width: 22px;
  height: auto;
  cursor: pointer;
}
.socialicondiv img:hover {
  filter: contrast(160%);
}
.socialicondiv img:last-child {
  margin-right: 0px;
}
.creditCardLink p {
  margin: 1.5em 0 0;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  color: #5d5d5d;
}
.creditCardLink p a {
  color: unset;
  /* text-decoration: none; */
}
.creditCardLink p a:hover {
  /* text-decoration: underline; */
  color: #212121;
}

/****outdor Dropdown List style ***/
.outdoordropdown .megamenustart .headerpoprover {
  max-height: 250px;
}

/***** Moblie screens ****/

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .topheaderdiv {
    display: block;
  }
  .topheaderinnerdiv {
    display: block;
    width: 100%;
  }
  .menu-icon {
    visibility: visible;
  }
  .bottomheader {
    display: none;
    height: 0;
  }
  .topheadertext {
    border-right: none;
    padding-bottom: 8px;
  }
  .headersectionstart {
    background-color: #212529;
  }
  p.bootomheadertext {
    color: #fff;
  }
  .logodiv {
    position: relative;
    width: 60px;
    height: 60px;
  }
  .topheaderdiv {
    padding: 13px 14px;
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 0;
  }
  .footerTopSec {
    padding: 1em 2em;
  }
  .footcopyrightsec {
    padding: 1.1em 1em;
  }
  .footcopyrightsec p {
    font-size: 70%;
  }
}
